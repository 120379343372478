import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const HelmetEl = (props) => (
    <HelmetProvider>
        <Helmet>
            <html lang={props.lang} />
            <title>{props.title}</title>
            <meta name='description' content={props.description} />
            <meta name='keywords' content={props.keywords} />
        </Helmet>
    </HelmetProvider>
);

export default HelmetEl;
