import React, { memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './styles.module.css';
import { useContextLanguage } from '../../../hooks/useLanguage';

function LanguageSwitcher({ onChangeProps }) {
    const data = useContextLanguage();
    const navigate = useNavigate();
    const location = useLocation();

    // // useEffect(() => {
    //     let newPath;
    // //     const currentLanguage = localStorage.getItem('language');
    // //
    // //     if (data) {
    // //         if (currentLanguage) {
    // //             data.setLanguage(currentLanguage);
    // //         }
    // //
    //         if (data.language === 'EN') {
    //             newPath = location.pathname.startsWith('/en') ? location.pathname : `/en${location.pathname}`;
    //         }
    //
    //         if (data.language === 'RU') {
    //             newPath = location.pathname.replace('/en', '');
    //         }
    //         navigate(newPath);
    // //     }
    // // }, [data]);

    if (data) {
        const { language, setLanguage } = data;
        const handleLanguageChange = (event) => {
            const newLang = event.target.value;

            if (onChangeProps) onChangeProps();
            setLanguage(event.target.value);
            localStorage.setItem('language', newLang);
            let newPath;

            if (newLang === 'EN') {
                newPath = location.pathname.startsWith('/en') ? location.pathname : `/en${location.pathname}`;
            }

            if (newLang === 'RU') {
                newPath = location.pathname.replace('/en', '');
            }
            navigate(newPath);
        };

        return (
            <div className={styles.switcher}>
                <input
                    type='radio'
                    value='RU'
                    id='RU'
                    className={styles.switcher__input}
                    checked={language === 'RU'}
                    onChange={handleLanguageChange}
                />
                <label className={language === 'RU' ? styles['switcher__label--checked'] : styles.switcher__label}
                    htmlFor='RU'>RU</label>
                <input
                    type='radio'
                    value='EN'
                    id='EN'
                    className={styles.switcher__input}
                    checked={language === 'EN'}
                    onChange={handleLanguageChange}
                />
                <label className={language === 'EN' ? styles['switcher__label--checked'] : styles.switcher__label}
                    htmlFor='EN'>ENG</label>
            </div>
        );
    }
}

export default memo(LanguageSwitcher);
