import React from 'react';
import Icon from '@/components/Icon/Icon.jsx';
import styles from './styles.module.css';

const Button = (props) => {
    const buttonTypes = props.buttonTypes || [];
    const buttonClassNames = buttonTypes
        .map((buttonType) => `${styles[`button_${buttonType}`]}`)
        .join(' ');
    const onClick = props.onClick || (() => {});

    const button = (<button
        onClick={onClick}
        tabIndex={((props.isLoading || props.isDisabled || props.tabIndex === -1) ? -1 : props.tabIndex || 0)}
        className={`${props.isLoading ? `${styles.button_loading}` : ''} ${styles.button} ${buttonClassNames} ${props.className || ''}`}
        disabled={!!props.isDisabled}
        aria-label={props.ariaLabel || ''}
    >
        {props.buttonText
            ? <span className={styles.button__text}>{props.buttonText}</span>
            : ''}

        {props.iconName
            ? <Icon
                iconName={props.iconName}
                viewBox={24}
            /> : ''}
    </button>);

    const linkButton = (<a
        href={props.href}
        onClick={onClick}
        rel='noreferrer'
        tabIndex={((props.isLoading || props.isDisabled || props.tabIndex === -1) ? -1 : props.tabIndex || 0)}
        target={(props.isBlank ? '_blank' : '_self')}
        className={`${props.isLoading ? `${styles.button_loading}` : ''} ${props.isDisabled ? `${styles.button_disabled}` : ''} ${styles.button} ${buttonClassNames} ${props.className || ''}`}
        aria-label={props.ariaLabel || ''}
    >
        {props.buttonText
            ? <span className={styles.button__text}>{props.buttonText}</span>
            : ''}

        {props.iconName
            ? <Icon
                iconName={props.iconName}
                viewBox={24}
            /> : ''}
    </a>);

    return props.isLink ? linkButton : button;
};

export default Button;
