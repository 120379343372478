import React from 'react';

import styles from './styles.module.css';

const Spacer = ({ height, mobileHeight }) => (
    <div>
        <div className={styles.spacer} style={{ height: `${height}px` }} />
        <div className={styles.spacer_mobile} style={{ height: `${mobileHeight}px` }} />

    </div>
);

export default Spacer;
