export default function formatPhoneNumber(phoneNumber) {
    const cleanedNumber = phoneNumber.replace(/\D/g, '');

    const countryCode = cleanedNumber.slice(0, 1);
    const cityCode = cleanedNumber.slice(1, 4);
    const restOfNumber = cleanedNumber.slice(4);

    const formattedNumber = `+${countryCode} ${cityCode} ${restOfNumber.slice(0, 3)}-${restOfNumber.slice(3, 5)}-${restOfNumber.slice(5)}`;

    return formattedNumber;
}
