import React from 'react';
import classnames from 'classnames';
import HeaderNavBar from '@/components/UiKit/Header/HeaderNavBar/HeaderNavBar.jsx';
import Button from '@/components/UiKit/Button/Button.jsx';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { useContextLanguage } from '../../../hooks/useLanguage';
import styles from './styles.module.css';

// conciergeRedirect - временный параметр для редиректа на странице консьерж
const Header = ({ withMenu = true, langSwitcher = false, conciergeRedirect = false }) => {
    const [isHeaderOpened, setHeaderOpenedState] = React.useState(false);
    const [isHeaderSticked, setHeaderStickedState] = React.useState(false);
    const [windowScrollY, setWinowScrollY] = React.useState(0);
    const { language } = useContextLanguage();
    const menuTitle = language === 'RU' ? 'Меню' : 'Menu';
    const clientButton = language === 'RU' ? 'Стать клиентом' : 'Become a client';

    const toggleHeaderOpenedState = React.useCallback((shouldScrollBack = true) => {
        setHeaderOpenedState(!isHeaderOpened);

        if (!isHeaderOpened) {
            setWinowScrollY(window.scrollY);
            document.body.classList.add('noscroll');

            return;
        }

        document.body.classList.remove('noscroll');

        if (shouldScrollBack) {
            window.scroll(0, windowScrollY);
        }
    }, [isHeaderOpened, windowScrollY, document.body]);

    const getNavButtonIconSrc = () => (isHeaderOpened ? 'navbutton_close' : 'navbutton_open');
    const headerScrollHandler = () => { setHeaderStickedState(window.scrollY > 0); };
    const resetHeaderStickedState = () => {
        if (isHeaderOpened) toggleHeaderOpenedState();
    };

    const becomeLink = React.useMemo(() => {
        const langRU = language === 'RU';

        if (langRU) return 'https://www.sberbank.ru/pb/forms';

        if (conciergeRedirect) {
            return langRU ? '/#contacts' : '/en/#contacts';
        }

        return '#contacts';
    }, [language]);

    React.useEffect(() => {
        document.addEventListener('scroll', headerScrollHandler);

        return () => document.removeEventListener('scroll', headerScrollHandler);
    }, []);

    return (
        <header
            className={classnames(styles.header, {
                header_opened: isHeaderOpened,
                [styles.header_opened]: isHeaderOpened,
                [styles.header_sticky]: isHeaderSticked,
            })}
        >
            <div
                className={classnames(styles.header__content, 'container')}
            >
                <div className={styles['header__content-left']}>
                    <a
                        className={styles.header__logo}
                        href={language === 'EN' ? '/en/' : '/'}
                        aria-label="На главную страницу"
                    >
                        <img
                            className={styles['header__logo-image']}
                            src={`${process.env.PUBLIC_URL}img/icons/logo_main.svg`}
                            alt="Sber Private Banking"
                        />
                    </a>

                    <a
                        className={styles['header__logo-tablet']}
                        href={language === 'EN' ? '/en/' : '/'}
                        aria-label="На главную страницу"
                    >
                        <img
                            className={styles['header__logo-image']}
                            src={`${process.env.PUBLIC_URL}img/icons/logo_main-tablet.svg`}
                            alt="Sber Private Banking"
                        />
                    </a>

                    <a
                        className={styles['header__logo-mobile']}
                        href={language === 'EN' ? '/en/' : '/'}
                        aria-label="На главную страницу"
                    >
                        <img
                            className={styles['header__logo-image']}
                            src={`${process.env.PUBLIC_URL}img/icons/logo_main-mobile.svg`}
                            alt="Sber Private Banking"
                        />
                    </a>

                    {withMenu && (<button
                        className={styles['header__nav-button']}
                        onClick={toggleHeaderOpenedState}
                    >
                        <div className={styles['header__nav-button-icon']}>
                            <img
                                src={`${process.env.PUBLIC_URL}img/icons/${getNavButtonIconSrc()}.svg`}
                                alt="Навигационное меню"
                            />
                        </div>

                        <span className={styles['header__nav-button-text']}>{menuTitle}</span>
                    </button>)}
                </div>

                <div className={styles['header__content-right']}>
                    {langSwitcher
                        && <div className={styles['header__content-right-desktop']}>
                            <LanguageSwitcher onChangeProps={resetHeaderStickedState}/>
                        </div>}
                    <Button
                        isLink={true}
                        isBlank={false}
                        className={styles['header__become-client-main']}
                        onClick={() => {
                            window.ym(24721250, 'reachGoal', 'button_become');
                            resetHeaderStickedState();
                        }}
                        href={becomeLink}
                        buttonText={clientButton}
                    />
                </div>

                <div className={styles['header__content-right-mobile']}>
                    <a
                        className={`${styles['header__button-phone-link']}`}
                        href="tel:+74957818778"
                        onClick={() => { window.ym(24721250, 'reachGoal', 'button_phone'); }}
                        aria-label="позвонить по номеру +7 495 781 87 78"
                    >
                        <Button
                            className={`${styles['header__button-phone']}`}
                            buttonTypes={['small', 'no-text', 'nomargin']}
                            iconName='phone'
                            iconViewBox={20}
                            tabIndex={-1}
                        />
                    </a>

                    {withMenu && (
                        <button
                            className={`${styles['header__nav-button-mobile']}`}
                            onClick={toggleHeaderOpenedState}
                        >
                            <div className={styles['header__nav-button-icon']}>
                                <img
                                    src={`${process.env.PUBLIC_URL}img/icons/${getNavButtonIconSrc()}.svg`}
                                    alt="Навигационное меню"
                                />
                            </div>
                        </button>
                    )}

                    <Button
                        isLink={true}
                        isBlank={false}
                        href={becomeLink}
                        className={styles['header__become-client']}
                        buttonTypes={['small']}
                        buttonText={clientButton}
                        onClick={() => {
                            window.ym(24721250, 'reachGoal', 'button_become');
                            resetHeaderStickedState();
                        }}
                    />
                </div>
            </div>

            {isHeaderOpened
                && <>
                    {langSwitcher
                        && <div className={styles['header__nav-lang']}>
                            <LanguageSwitcher />
                        </div>}
                    <HeaderNavBar />
                </>}
        </header>
    );
};

export default Header;
