import React, { useState, useEffect } from 'react';
import formatPhoneNumber from '@/utils/formatted';
import styles from './styles.module.css';
import { useContextLanguage } from '../../hooks/useLanguage';

const Contacts = ({
    isDefault = true,
    title,
    description,
    employees,
    email = 'info_pb@sberbank.ru',
    emailDescription = 'для писем и обращений',
    numberOperator = '+74957818778',
    numberOperatorDescription = 'выделенная линия Sber Private Banking для звонков с территории РФ и из-за рубежа',
    numberFree = '900',
    numberFreeDescription = 'для бесплатных звонков с территории РФ',
    feedbackForm = false,
    feedbackFormRef = '',
    titleDefault = 'Свяжитесь с нами',
    feedbackFormTitle = 'Обратный звонок',
    feedbackFormDescription = 'оставьте свои контакты, мы свяжемся с&nbsp;вами в ближайшее время',
    constructor = true,
    listTypeContent = { type: false, value: [] },
    withParentSection = false,
}) => {
    const [defaultData, setDefaultData] = useState({
        description: {
            type: 'description',
            value: titleDefault,
        },
        column: {
            type: 'column',
            value: [
                {
                    type: 'shortTel',
                    value: numberFree,
                    link: numberFree,
                    description: numberFreeDescription,
                },
                {
                    type: 'tel',
                    value: formatPhoneNumber(numberOperator),
                    link: numberOperator,
                    description: numberOperatorDescription,
                },
                {
                    type: 'email',
                    value: email,
                    link: email,
                    description: emailDescription,
                },
            ],
        },
    });

    const { language } = useContextLanguage();

    useEffect(() => {
        if (isDefault && !constructor) {
            fetch('/static/contacts_default.json')
                .then((res) => res.json())
                .then((res) => {
                    const dataRu = res.ru.value;
                    let dataEng = null;

                    if (res.eng) {
                        dataEng = res.eng.value;
                    }
                    let currentDataObject = {};

                    if (language === 'RU') {
                        currentDataObject = dataRu;
                    } else {
                        currentDataObject = dataEng;
                    }

                    const result = currentDataObject.reduce((acc, el) => {
                        acc[el.type] = el;

                        return acc;
                    }, {});
                    setDefaultData(result);
                });
        }
    }, [isDefault, language]);
    const withoutSpaces = withParentSection ? `${styles.withoutSpaces}` : '';

    if (isDefault) {
        const itemList = defaultData.column.value.map((item) => {
            let hrefPrefix = '';

            if (item.type === 'shortTel' || item.type === 'tel') hrefPrefix = 'tel:';

            if (item.type === 'email') hrefPrefix = 'mailto:';

            return (
                <div key={hrefPrefix + item.type } className={styles.contacts__item}>
                    <a href={`${hrefPrefix}${item.link}`} className={styles.contacts__phone}>{item.value}</a>
                    <p className={styles.contacts__text} dangerouslySetInnerHTML={{ __html: item.description }} ></p>
                </div>
            );
        });

        return (
            <section className={'section'}>
                <div id='contacts' className={styles.contacts__anchor}></div>
                <div className={`container ${styles.contacts} ${withoutSpaces}`}>
                    <h3 className={styles.contacts__title}>{defaultData.description.value}</h3>
                    <div className={styles.contacts__list}>
                        {itemList}
                    </div>
                    {(defaultData.feedbackForm || feedbackForm) && (
                        <div className={styles['contacts__feedback-form']}>
                            <div className={styles['contacts__item_feedback-form']}>
                                <a href={feedbackForm ? feedbackFormRef : defaultData.feedbackForm.link} className={styles.contacts__phone}>
                                    {feedbackForm ? feedbackFormTitle : defaultData.feedbackForm.value}
                                </a>
                                <p className={styles.contacts__text}
                                    dangerouslySetInnerHTML={{ __html: feedbackForm ? feedbackFormDescription : defaultData.feedbackForm.description }} ></p>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        );
    }

    if (listTypeContent.listTypeContent && !isDefault) {
        const listContent = listTypeContent.value.map((item, index) => {
            let hrefPrefix = '';

            if (item.type === 'shortTel' || item.type === 'tel') hrefPrefix = 'tel:';

            if (item.type === 'email') hrefPrefix = 'mailto:';

            if (item.type === 'link') hrefPrefix = '';

            const linkUnderlineStyle = ((item.type === 'link') || (item.type === 'email')) ? styles['contacts__phone-link'] : '';

            const handleClickMetrics = () => {
                if (item.ym.length) {
                    window.ym(...item.ym);
                }
            };

            return (
                <div key={index} className={`${styles.contacts__item} ${styles.listTypeContentItem}`}>
                    <a onClick={handleClickMetrics} href={`${hrefPrefix}${item.link}`} className={`${styles['contacts__phone-list']} ${linkUnderlineStyle}`}>{item.value}</a>
                    <p className={styles.contacts__text} dangerouslySetInnerHTML={{ __html: item.description }} ></p>
                </div>
            );
        });

        return (
            <section className={'section'}>
                <div id='contacts' className={styles.contacts__anchor}></div>
                <div className={`container ${styles.contacts} ${styles['listTypeContent-container']} ${withoutSpaces}`}>
                    <h3 className={styles.contacts__title}>{defaultData.description.value}</h3>
                    <div className={`${styles.contacts__list} ${styles.listTypeContent}`}>
                        {listContent}
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className={'section'}>
            <div id='contacts' className={styles.contacts__anchor}></div>
            <div className={`container ${styles.contacts}`}>
                <h3 className={styles.contacts__title}>{title}</h3>
                <span className={styles.contacts__description}>{description}</span>
                <div className={styles.contacts__employees}>
                    {employees.map((el, index) => (
                        <div className={styles.contacts__employee} key={index}>
                            <img src={`${process.env.PUBLIC_URL}upload/${el.image}`} className={styles['contacts__employee-image']} />
                            <div className={styles['contacts__employee-info']}>
                                <span className={styles['contacts__employee-name']}>{el.name}</span>
                                <span className={styles['contacts__employee-job']}>{el.jobTitle}</span>
                                <div className={styles['contacts__employee-contacts']}>
                                    {el.tgLink && <a href={`https://t.me/${el.tgLink}`}><img src={`${process.env.PUBLIC_URL}img/tg.svg`}/>{el.tgText || el.tgLink}</a>}
                                    {el.phoneNumber && <a href={`tel:${el.phoneNumber}`}><img src={`${process.env.PUBLIC_URL}img/phone.svg`}/>{el.phoneText || el.phoneNumber}</a>}
                                    {el.emailAddress && <a href={`mailto:${el.emailAddress}`}><img src={`${process.env.PUBLIC_URL}img/mail_dark.svg`}/>{el.emailText || el.emailAddress}</a>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Contacts;
