import React, { useState, useEffect } from 'react';
import Icon from '@/components/Icon/Icon.jsx';
import { navListRu, navListEn } from './NavListConst';
import { useContextLanguage } from '../../../../hooks/useLanguage';
import styles from './styles.module.css';

const HeaderNavList = () => {
    const [navListLang, setNavListLang] = useState(navListRu);
    const { language } = useContextLanguage();
    const getClassName = (searchValue) => {
        const defaultClassName = `${styles['header__navlist-item-anchore']}`;

        const currentPageClassName = window.location.href.includes(searchValue)
            ? styles['header__navlist-item-anchore_current']
            : '';

        return `${defaultClassName} ${currentPageClassName}`;
    };

    useEffect(() => {
        if (language === 'RU') {
            setNavListLang(navListRu);
        } else {
            setNavListLang(navListEn);
        }
    }, [language]);

    const renderNavList = navListLang.map((item) => (
        <li key={item.name} className={styles['header__navlist-item']}>
            <a href={`${process.env.PUBLIC_URL}${item.path}`} className={getClassName(item.className)}>
                {item.name}

                <Icon
                    className={styles['header__navlist-item-icon']}
                    iconName='nextArrow'
                    viewBox={20}
                />
                <Icon
                    className={`${styles['header__navlist-item-icon']} ${styles['header__navlist-item-icon_hover']}`}
                    iconName='nextArrowLong'
                    viewBox={23}
                />
            </a>
        </li>
    ));

    return (
        <ul className={styles.header__navlist}>
            {renderNavList}
        </ul>
    );
};

export default HeaderNavList;
