import React from 'react';
import Contacts from '@/components/Contacts/Contacts.jsx';
import Footer from '@/components/Footer/Footer.jsx';
import Header from '@/components/UiKit/Header/Header.jsx';
import HelmetEl from '@/components/HelmetEl/HelmetEl.jsx';
import PageBlur from '@/components/PageBlur/PageBlur.jsx';
import styles from './style.module.css';

const NotFound = () => (
    <div
        className={`page ${styles.page}`}
    >
        <HelmetEl
            lang='ru' amp
            title='Sber Private Banking'
            description='Главная страница Sber Private Banking'
            keywords='Sber, Private, Banking, банкинг'
        />

        <Header withMenu={false} />

        <section className={`section ${styles.section}`}>
            <div className={`container ${styles.container}`}>
                <img
                    className={`section ${styles['section__404-img']}`}
                    src="/img/404.png"
                    alt="Страница не найдена"
                />

                <h3
                    className={styles.section__header}
                >
                        Страница не найдена
                </h3>

                <p
                    className={styles.section__description}
                >
                        Кажется, что-то пошло не&nbsp;так! Страница, которую вы&nbsp;запрашиваете
                        не&nbsp;существует. Возможно, она устарела, была удалена или был введён
                        неверный адрес в&nbsp;адресной строке.
                </p>
            </div>
        </section>

        <Contacts constructor={false}/>

        <Footer isFull={false} />

        <PageBlur />
    </div>
);

export default NotFound;
