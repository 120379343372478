import React, {
    useState,
    createContext, useContext, useEffect,
} from 'react';

const LanguageContext = createContext();

export function useLanguage() {
    const [language, setLanguage] = useState('RU');

    return [language, setLanguage];
}

export function LanguageProvider({ children }) {
    const [language, setLanguage] = useLanguage();

    useEffect(() => {
        if (window.location.href.split('/').includes('en')) setLanguage('EN');
        else setLanguage('RU');
    }, []);

    return (
        <LanguageContext.Provider value={ { language, setLanguage } }>
            {children}
        </LanguageContext.Provider>
    );
}

export function useContextLanguage() { return useContext(LanguageContext); }
