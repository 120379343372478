export const navListRu = [
    {
        name: 'Новости',
        path: 'news',
        className: 'news',
    }, {
        name: 'Банковские сервисы',
        path: 'banking_services',
        className: 'banking_services',
    }, {
        name: 'Офисы',
        path: 'offices',
        className: 'offices',
    },
];
export const navListEn = [
    {
        name: 'News',
        path: 'en/news',
        className: 'news',
    }, {
        name: 'Banking services',
        path: 'en/banking_services',
        className: 'banking_services',
    }, {
        name: 'Offices',
        path: 'en/offices',
        className: 'offices',
    },
];
