import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '@/components/Footer/styles.module.css';
import Spacer from '@/components/constructor/Spacer/Spacer.jsx';
import { useContextLanguage } from '../../hooks/useLanguage';

const defaultFooter = [
    {
        type: 'columns',
        columns:
            [
                {
                    texts:
                        [
                            {
                                type: 'paragraph',
                                text: 'Sber Private Banking — формат частного банковского обслуживания. Банковские продукты и услуги предоставляет ПАО СберБанк. ',
                            },
                        ],
                },
                {
                    texts:
                        [
                            {
                                type: 'paragraph',
                                text: 'Генеральная лицензия Банка России на осуществление банковских операций от 11 августа 2015 года. Регистрационный номер — 1481',
                            },
                        ],
                },
            ],
    },
    {
        type: 'columns',
        columns:
            [
                {
                    texts:
                        [
                            {
                                type: 'paragraph',
                                text: "<a href='https://www.sberbank.ru/privacy/recommtechrules'>На информационном ресурсе применяются рекомендательные технологии.</a>",
                            },
                        ],
                },
                {
                    texts:
                        [],
                },
            ],
    },
];

const Footer = ({ isFull = true, partners = [], elems = [] }) => {
    const [footer, setFooter] = useState(defaultFooter);

    const { language } = useContextLanguage();

    useEffect(() => {
        fetch('/static/footer_default.json')
            .then((result) => result.json())
            .then((result) => {
                if (result && language) setFooter(result[language]);
                else if (result) setFooter(result);
            })
            .catch(() => {});
    }, [language]);

    const logoBlock = () => (
        <a className={styles['footer__logo-link__block']} href={language === 'EN' ? '/en/' : '/'} aria-label="на главную страницу">
            <img
                className={styles['footer__logo-link']}
                src={`${process.env.PUBLIC_URL}img/logo_light.svg`}
                alt="Sber Private Banking"
            />
            <img
                className={styles['footer__logo-link_mobile']}
                src={`${process.env.PUBLIC_URL}img/logo_mobile_light.svg`}
                alt="Sber Private Banking"
            />
        </a>
    );

    const menuBlock = (links) => (
        <div className={`${styles.footer__info} ${styles.footer__menu}`}>
            <ul className={styles['footer__menu-list']}>
                {links.map((el, index) => (
                    <li key={index} className={styles['footer__menu-list-item']}>
                        <a className={styles['footer__menu-link']} href={`${process.env.PUBLIC_URL}${el.url}`} dangerouslySetInnerHTML={{ __html: el.text }}/>
                    </li>
                ))}
            </ul>
        </div>
    );

    const divider = () => (
        <div className={styles.footer__divider} />
    );

    const outerLinksBlock = (links) => (
        <div className={`${styles.footer__info} ${styles['footer__outer-links']}`}>
            <ul className={styles['footer__outer-links-list']}>
                {links.map((el, index) => (
                    <li key={index} className={styles['footer__outer-links-item']}>
                        <a className={styles['footer__outer-link']} target='_blank' rel='noreferrer' href={el.url} dangerouslySetInnerHTML={{ __html: el.text }}/>
                    </li>
                ))}
            </ul>
        </div>
    );

    const ourPartners = (links, title = 'Наши партнёры') => (
        <div className={`${styles.footer__info}`}>
            <h3 className={styles.footer__title}>{title}</h3>
            <div className={styles.footer__columns}>
                <div className={`${styles.footer__column} ${styles.footer__column_nomargin}`}>
                    {links.map((el, index) => {
                        if (index < links.length / 2) {
                            return <span className={`${styles['footer__copyright-text']} ${styles['footer__copyright-text_link']} ${styles['footer__copyright-text_spaced']}`} key={index}>
                                <a href={el.url} target='_blank' rel="noreferrer noopener" dangerouslySetInnerHTML={{ __html: el.text }} />
                            </span>;
                        }

                        return null;
                    })}
                </div>
                <div className={styles.footer__column}>
                    {links.map((el, index) => {
                        if (index >= links.length / 2) {
                            return <span className={`${styles['footer__copyright-text']} ${styles['footer__copyright-text_link']} ${styles['footer__copyright-text_spaced']}`} key={index}>
                                <a href={el.url} target='_blank' rel="noreferrer noopener" dangerouslySetInnerHTML={{ __html: el.text }} />
                            </span>;
                        }

                        return null;
                    })}
                </div>
            </div>
        </div>
    );

    const textBlock = (text) => (
        <p className={styles.footer__text} dangerouslySetInnerHTML={{ __html: text }} />
    );

    const copyrightText = (text, withHover = false, noBreak = false, isMain = false) => (
        <span
            className={`
            ${styles['footer__copyright-text']} 
            ${isMain ? styles['footer__copyright-text_main'] : ''} 
            ${withHover ? styles['footer__copyright-text_link'] : ''}
            ${noBreak ? styles['footer__copyright-text_no-break'] : ''}
            `}
            dangerouslySetInnerHTML={{ __html: text }}
        />
    );

    const textColumn = (texts, title) => (
        <div className={styles.footer__column}>
            {title && <h3 className={styles.footer__title}>{title}</h3>}
            {texts.map((el) => {
                if (el.type === 'copyright') return copyrightText(el.text, el.withHover, el.noBreak);

                if (el.type === 'copyright-main') return copyrightText(el.text, el.withHover, el.noBreak, true);

                return textBlock(el.text);
            })}
        </div>
    );

    const columnsBlock = (columns) => (
        <div className={`${styles.footer__info} ${styles.footer__columns}`}>
            {columns.map((el) => textColumn(el.texts, el.title))}
        </div>
    );

    if (!isFull) {
        return (
            <footer className={`${styles.footer} ${styles.footer_short}`}>
                <div className={styles.footer__divider}></div>
                <div className={styles.footer__info}>
                    <div className={styles.footer__container}><p
                        className={styles.footer__text}>Sber Private Banking — формат частного банковского
                        обслуживания. Банковские продукты и услуги предоставляет ПАО Сбербанк.</p><p
                        className={styles.footer__text}>Генеральная лицензия Банка России на
                        осуществление банковских операций от 11 августа 2015 года. Регистрационный номер — 1481</p>
                    </div>
                </div>
            </footer>
        );
    }

    if (isFull && elems.length > 0) {
        return (
            <footer className={styles.footer}>
                <div className={styles.footer__container}>
                    {elems.map((el) => {
                        switch (el.type) {
                            case 'menu':
                                return menuBlock(el.links);
                            case 'divider':
                                return divider();
                            case 'outerLinks':
                                return outerLinksBlock(el.links);
                            case 'logo':
                                return logoBlock();
                            case 'columns':
                                return columnsBlock(el.columns);
                            case 'partners':
                                return ourPartners(el.partners, el.title);
                            case 'spacer':
                                return <Spacer height={el.height} mobileHeight={el.mobileHeight} />;
                            default:
                                return <div />;
                        }
                    })}
                </div>
            </footer>
        );
    }

    return (
        <footer className={styles.footer}>
            <div className={styles.footer__container}>
                {footer.map((el) => {
                    switch (el.type) {
                        case 'menu':
                            return menuBlock(el.links);
                        case 'divider':
                            return divider();
                        case 'outerLinks':
                            return outerLinksBlock(el.links);
                        case 'logo':
                            return logoBlock();
                        case 'columns':
                            return columnsBlock(el.columns);
                        case 'partners':
                            return ourPartners(el.partners, el.title);
                        case 'spacer':
                            return <Spacer height={el.height} mobileHeight={el.mobileHeight} />;
                        default:
                            return <div />;
                    }
                })}
            </div>
        </footer>
    );
};

export default Footer;
