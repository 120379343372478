import React from 'react';
import HeaderNavList from '@/components/UiKit/Header/HeaderNavList/HeaderNavList.jsx';
import styles from './styles.module.css';

const HeaderNavBar = () => (
    <nav className={`${styles.header__navbar} container`}>
        <div className={styles['header__navbar-content']}>
            <HeaderNavList />
        </div>
    </nav>
);

export default HeaderNavBar;
