import React from 'react';
import styles from './styles.module.css';

const Loader = () => (
    <div
        className={`${styles['loader-wrapper']}`}
    >
        <div className={styles.loader}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default Loader;
