import React from 'react';

import svgIconMapper from '@/mappers/svgIconMapper';

const Icon = (props) => {
    const svgInnerHtml = props.iconName
        ? (svgIconMapper[props.iconName][props.viewBox] || '')
        : '';

    return (
        <svg
            tabIndex={-1}
            className={props.className || ''}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d={svgInnerHtml}
            />
        </svg>
    );
};

export default Icon;
