import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter,
    Routes,
    Route,
} from 'react-router-dom';
const Index = lazy(() => import('@/pages/Index/Index.jsx'));
const Calculator = lazy(() => import('@/pages/Calculator/Calculator.jsx'));
const News = lazy(() => import('@/pages/News/News.jsx'));
import NotFound from '@/pages/NotFound/NotFound.jsx';
import '@/assets/styles/fonts.module.css';
import '@/assets/styles/typography.module.css';
import '@/assets/styles/colors.module.css';
import '@/assets/styles/reset.module.css';
import '@/assets/styles/globals.module.css';
import { QueryJsonProvider } from '@/hooks/useQueryJson.jsx';
import { LanguageProvider } from '@/hooks/useLanguage.jsx';
const BankingServices = lazy(() => import('@/pages/BankingServices/BankingServices.jsx'));
const Offices = lazy(() => import('./pages/Offices/Offices'));
const Aeroflot = lazy(() => import('./pages/Aeroflot/Aeroflot'));
const Concierge = lazy(() => import('./pages/Concierge/Concierge'));
import Loader from './components/Loader/Loader';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <LanguageProvider>
        <BrowserRouter>
            <Routes>
                <Route index path='/' element={
                    <Suspense fallback={<Loader />}>
                        <QueryJsonProvider sources={['/static/main.json']}>
                            <Index/>
                        </QueryJsonProvider>
                    </Suspense>
                } />
                <Route path='/en' element={
                    <Suspense fallback={<Loader />}>
                        <QueryJsonProvider sources={['/static/en/main_en.json']}>
                            <Index/>
                        </QueryJsonProvider>
                    </Suspense>
                } />
                <Route path='/calculator' element={
                    <Suspense fallback={<Loader />}>
                        <QueryJsonProvider sources={['/static/calculator.json']}>
                            <Calculator />
                        </QueryJsonProvider>
                    </Suspense>
                } />
                
                <Route path='/news' element={
                    <Suspense fallback={<Loader />}>
                        <QueryJsonProvider sources={['/static/news.json']}>
                            <News />
                        </QueryJsonProvider>
                    </Suspense>
                } />
                <Route path='/en/news' element={
                    <Suspense fallback={<Loader />}>
                        <QueryJsonProvider sources={['/static/en/news_en.json']}>
                            <News />
                        </QueryJsonProvider>
                    </Suspense>
                } />
                <Route path='/banking_services' element={
                     <Suspense fallback={<Loader />}>
                        <QueryJsonProvider sources={['/static/banking_services.json']}>
                            <BankingServices />
                        </QueryJsonProvider>
                    </Suspense>
                } />

                <Route path='/en/banking_services' element={
                    <Suspense fallback={<Loader />}>
                        <QueryJsonProvider sources={['/static/en/banking_services_en.json']}>
                            <BankingServices />
                        </QueryJsonProvider>
                    </Suspense>
                } />

                <Route path='/offices' element={
                    <Suspense fallback={<Loader />}>
                        <QueryJsonProvider sources={['/static/offices.json']}>
                            <Offices />
                        </QueryJsonProvider>
                    </Suspense>
                } />
                <Route path='/en/offices' element={
                    <Suspense fallback={<Loader />}>
                        <QueryJsonProvider sources={['/static/en/offices_en.json']}>
                            <Offices />
                        </QueryJsonProvider>
                    </Suspense>
                } />

                 <Route path='/concierge' element={
                     <Suspense fallback={<Loader />}>
                         <QueryJsonProvider sources={['/static/concierge.json']}>
                            <Concierge />
                        </QueryJsonProvider>
                     </Suspense>
                } />

                <Route path='/en/concierge' element={
                   <Suspense fallback={<Loader />}>
                    <QueryJsonProvider sources={['/static/en/concierge_en.json']}>
                        <Concierge />
                    </QueryJsonProvider>
                    </Suspense>
                } /> 
                <Route path='/aeroflot' element={
                    <Suspense fallback={<Loader />}>
                        <QueryJsonProvider sources={['/static/aeroflot.json']}>
                            <Aeroflot />
                        </QueryJsonProvider>
                    </Suspense>
                   
                } />
                <Route path='/en/aeroflot' element={
                   <Suspense fallback={<Loader />}>
                        <QueryJsonProvider sources={['/static/en/aeroflot_en.json']}>
                            <Aeroflot />
                        </QueryJsonProvider>
                  </Suspense>
                } />

                <Route path='*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    </LanguageProvider>,
);
