/* eslint-disable import/prefer-default-export */
// import React, {
//     useEffect, useState,
//     createContext, useContext, useMemo, useCallback,
// } from 'react';
// import useThrottle from './useThrottle';

// const QueryJsonContext = createContext();

// export function useQueryJson(sources) {
//     const [json, setJson] = useState(null);
//     const [loaded, setIsLoaded] = useState(false);
//     const [error, setError] = useState(false);
//     const [forceCaching, setForceCaching] = useState(true);

//     const realTime = new Date().getTime();
//     const throttledTime = useThrottle(realTime, 3600000);
//     const cacheKEY = useMemo(() => sources.join(''), [sources]);
//     const cachedJson = useMemo(() => sessionStorage.getItem(cacheKEY), [cacheKEY]);

//     const fetchJSON = useCallback(async () => {
//         setIsLoaded(false);
//         const requests = await sources.map((source) => fetch(source).then((response) => response.json()).catch(() => setError(true)));
//         const responses = await Promise.all(requests);
//         const mergedJSON = responses.reduce((acc, curr) => ({ ...acc, ...curr }), { cacheTimestamp: realTime });
//         setJson(mergedJSON);

//         if (mergedJSON.forceCaching) {
//             setForceCaching(true);
//             sessionStorage.setItem(cacheKEY, JSON.stringify(mergedJSON));
//         } else {
//             setForceCaching(false);
//             sessionStorage.removeItem(cacheKEY);
//         }
//         // Делаем искусственный дэлэй для того, чтобы избежать моргания страниц при быстрой загрузке
//         setTimeout(() => setIsLoaded(true), 400);
//     }, [sources, forceCaching]);

//     useEffect(() => {
//         if (forceCaching && cachedJson) {
//             const parsedJson = JSON.parse(cachedJson);

//             if (throttledTime - parsedJson.cacheTimestamp > 3590000) {
//                 // refetch
//                 sessionStorage.removeItem(cacheKEY);
//                 fetchJSON();
//             } else {
//                 // cache
//                 setJson(parsedJson);
//                 setIsLoaded(true);
//             }
//         } else {
//             // first fetch
//             fetchJSON();
//         }
//     }, [cachedJson, fetchJSON, throttledTime, forceCaching]);

//     return [json, loaded, error];
// }

// export function QueryJsonProvider({ children, sources }) {
//     const [json, loaded, error] = useQueryJson(sources);

//     return (
//         <QueryJsonContext.Provider value={ { json, loaded, error } }>
//             {children}
//         </QueryJsonContext.Provider>
//     );
// }

// export function useContextJson() { return useContext(QueryJsonContext); }

import React, {
    useEffect, useState,
    createContext, useContext,
} from 'react';

const QueryJsonContext = createContext();

export function useQueryJson(sources) {
    const [json, setJson] = useState(null);
    const [loaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        const fetchJSON = async () => {
            setIsLoaded(false);
            const requests = await sources.map((source) => fetch(source).then((response) => response.json()).catch(() => setError(true)));
            const responses = await Promise.all(requests);
            const mergedJSON = responses.reduce((acc, curr) => ({ ...acc, ...curr }), {});
            setJson(mergedJSON);
            setIsLoaded(true);
        };
        fetchJSON();
    }, [sources]);

    return [json, loaded, error];
}

export function QueryJsonProvider({ children, sources }) {
    const [json, loaded, error] = useQueryJson(sources);

    return (
        <QueryJsonContext.Provider value={ { json, loaded, error } }>
            {children}
        </QueryJsonContext.Provider>
    );
}

export function useContextJson() { return useContext(QueryJsonContext); }
